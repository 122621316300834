export interface UserPreferences {
  /**
   * The font size used for :
   * Numéro de train
   * Horaire
   * Origine/Destination
   * Type matériel
   * Commentaire
   */
  eqFontSize: number;
  /**
   * Do user want to show the dots
   * for Itineraires Secondaires.
   */
  showItiSecondaires: boolean;
}
