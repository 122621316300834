import { UserPermissionCategory } from './user';

/**
 * Enum representing all user permissions.
 */
export enum USER_PERMISSION {
  CERTIFICATION = 'CERTIFICATION',
  CORRESPONDANCES = 'CORRESPONDANCES',
  EDITION = 'EDITION',
  EXPORT_GROIX = 'EXPORT_GROIX',
  IMPORT_GROIX = 'IMPORT_GROIX',
  IMPORT_GROIX_F2K = 'IMPORT_GROIX_F2K',
  IMPORT_HOUAT = 'IMPORT_HOUAT',
  IMPORT_HOUAT_ONLY = 'IMPORT_HOUAT_ONLY',
  SYNCHRO_HOUAT = 'SYNCHRO_HOUAT',
  SYNCHRO_PASSE_MINUIT = 'SYNCHRO_PASSE_MINUIT',
  OPTIMISATION_GLOBALE = 'OPTIMISATION_GLOBALE',
  ROULEMENTS = 'ROULEMENTS',
  TRAVAUX = 'TRAVAUX',
  IMPORT_EXPORT_OPENGOV = 'IMPORT_EXPORT_OPENGOV',
  EXPORT_BASE_GARE = 'EXPORT_BASE_GARE',
}

export const USER_PERMISSIONS_WITH_CATEGORY: UserPermissionCategory = {
  // Category gare
  ADMIN_GARE: { label: 'Administrateur gare', category: 'Gare', value: 'ADMIN_GARE' },
  PARAM_GARE: { label: 'Paramétrage gare', category: 'Gare', value: 'PARAM_GARE' },

  // Category GOV
  CERTIFICATION: { label: 'Certification', category: 'GOV', value: 'CERTIFICATION' },
  COMPARAISON_PDT: { label: 'Comparaison PDT', category: 'GOV', value: 'COMPARAISON_PDT' },
  EDITION: { label: 'Edition GOV', category: 'GOV', value: 'EDITION' },
  EDITION_STATUT_CONFLIT: { label: 'Edition statut de conflit', category: 'GOV', value: 'EDITION_STATUT,CONFLIT' },
  CORRESPONDANCES: { label: 'Correspondances', category: 'GOV', value: 'CORRESPONDANCES' },
  OPTIMISATION_GLOBALE: { label: 'Optimisation globale', category: 'GOV', value: 'OPTIMISATION_GLOBALE' },
  ROULEMENTS: { label: 'Roulement', category: 'GOV', value: 'ROULEMENTS' },
  TRAVAUX: { label: 'Travaux', category: 'GOV', value: 'TRAVAUX' },

  // Category Import / Export
  IMPORT_GROIX: { label: 'Import GROIX', category: 'Import Export', value: 'IMPORT_GROIX' },
  IMPORT_EXPORT_OPENGOV: { label: 'Import/Export OpenGOV', category: 'Import Export', value: 'IMPORT_EXPORT_OPENGOV' },
  IMPORT_HOUAT: { label: 'Import HOUAT', category: 'Import Export', value: 'IMPORT_HOUAT' },
  IMPORT_HOUAT_ONLY: { label: 'Import HOUAT sans enchaînements', category: 'Import Export', value: 'IMPORT_HOUAT_ONLY' },
  SYNCHRO_HOUAT: { label: 'Synchro HOUAT', category: 'Import Export', value: 'SYNCHRO_HOUAT' },
  IMPORT_GROIX_F2K: { label: 'Import Fichier 2000', category: 'Import Export', value: 'IMPORT_GROIX_F2K' },

  EXPORT_GROIX: { label: 'Export GROIX', category: 'Import Export', value: 'EXPORT_GROIX' },
  EXPORT_BASE_GARE: { label: 'Export base gare', category: 'Import Export', value: 'EXPORT_BASE_GARE' },
  SYNCHRO_PASSE_MINUIT: { label: 'Synchronisation Passe-minuit', category: 'Import Export', value: 'SYNCHRO_PASSE_MINUIT' },
};
