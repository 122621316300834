/**
 * @file
 * A class managing all the PLAN DE TRANSPORT data (equilibres, conflicts...).
 * including useful mappings of conflicts to equilibres, etc.
 *
 * Typically, this data is editable/alterable via the frontend.
 */
import * as moment from 'moment';
import * as _ from 'lodash';

import { ConflictFilters } from '@app/gov-ui/state';

import {
  EquilibreDto,
  ColorByType,
  GovColorMode,
  TransportationPlanStatus,
  TypeMateriel,
  TransportationPlanDto,
  Train,
  F2K_METADATA_KEY,
  CONCEPTION_TP_DATE,
} from './transportation-plan';
import {
  ConflictDto,
  Conflict,
  ConflictCategory,
  createConflictFromDto,
  isConflictDto,
  createDtoFromConflict,
  StatutConflictDto,
  createStatutConflict,
  StatutConflictValue,
} from './conflict';
import {
  Equilibre,
  createEquilibreFromDto,
  createDtoFromEquilibre,
  eqGetConflictIds,
  eqGetConflictIncoherenceDonneesIds,
  eqDoesTrainPariteChange,
} from './equilibre';
import { CodeChantier, hasVaqMiQuais, Itineraire, Sens, VaqInfo, VoieAQuai, VoieSubType } from './station';
import { GovInfra } from './gov-infra';
import { TravauxOpDto, TravauxOp, createTravauxOpFromDto, TravauxOpType, travauxOpGetConflictIds } from './travaux-op';
import { eqGroupGetId, eqContainsTrainNumber } from './eq-utils';
import { EqGroupHighlight } from './eqgroup-highlight';
import { createIndispoItiFromDto, indispoItiGetConflictIds, IndispoItineraire, IndispoItineraireDTO } from './indispo-itineraire';
import { JourneeType } from '@app/conception/models/conception';
import { TravauxOpSelection } from './travaux-op-selection';
import { ConflictByHour } from './conflict-by-hour';
import { SourcePlanTransport } from './source-pdt';
import { UserPreferences } from './user-preferences';
import {
  Assemblage,
  AssemblageDTO,
  EquilibreComparison,
  EquilibreComparisonDTO,
  ModifiedTrain,
  PartialEquilibre,
  PasseMinuitComparison,
  PasseMinuitComparisonDTO,
  createPartialEquilibre,
  createPasseMinuitComparisonFromDto,
} from './passe-minuit-comparison';
import { TrainTypeLabel } from './train-type';
import {
  CompareElement,
  CompareElementType,
  createCompareTrain,
  CompareTrainState,
  CompareTrain,
  CompareEqState,
  CompareEquilibre,
  createCompareEquilibre,
} from './compare-element';
import { CompareTrainCategory, CompareTrainByCategory, CompareTrainSubCategory, CompareTrainBySubCategory } from './compare-train-category';
import { TransportationPlanComparison, TrainComparisonDTO, ModifiedField } from './transportation-plan-comparison';

export interface GovDataOptions {
  tpId: number;
  tpRid: string;
  tpDate: string;
  parametrageId: number;
  tpStatus: TransportationPlanStatus;
  tpOrigine: SourcePlanTransport;
  govInfra: GovInfra;
  eqGroupDtos: EquilibreDto[][];
  codesChantier: CodeChantier[];
  isConception: boolean;
  userPreferences: UserPreferences;
  filteredGovVaqList?: VaqInfo[];
  conflictDtos?: ConflictDto[];
  travauxOpDtos?: TravauxOpDto[];
  indispoItiDtos?: IndispoItineraireDTO[];
  eqGroupHighlights?: EqGroupHighlight[];
  govVaqList?: VaqInfo[]; // If set, this will override govInfra.vaqList
  modifCount?: number;
  journeeType?: JourneeType;
  lastAppliedJt?: string;
}

//

export class GovData {
  tpId: number;
  tpRid: string; // TP date in YYYY-MM-DD format
  tpDate: moment.Moment; // moment(YYYY-MM-DD)
  parametrageId: number;

  stationCode: string; // stored here as public prop for convenience
  modifCount: number;
  hash: string;
  travauxHash: string;
  isGovCertified: boolean;
  isGovJTValidated: boolean;
  isGovNotEditable: boolean;
  isGovSynchronized: boolean;
  isGovSynchronizedHouat: boolean;
  isGovSynchronizedPasseMinuit: boolean;

  isInfra2TMV: boolean;

  codesChantier: CodeChantier[];

  isConception: boolean;

  journeeType: JourneeType;

  lastAppliedJtDescription: string;
  lastAppliedJtId: number;

  tpStatus: TransportationPlanStatus;
  tpOrigine: SourcePlanTransport;

  // GOV display preferences
  userPreferences: UserPreferences;

  // Filter VAQ
  filteredGovVaqList?: VaqInfo[];

  private _isAnalysable = false; // true if a conflicts analysis can be triggered (because some eqGroups or travauxOps have changed)

  // eqGroups
  private eqGroupsMap: Map<string, Equilibre[]> = new Map();
  private eqIdsToEqGroupIds: Map<number, string> = new Map();
  private eqGroupHighlights: EqGroupHighlight[];
  private filteredEqGroups: Equilibre[][] = [];

  // conflicts
  private conflictsMap: Map<number, Conflict> = new Map();
  private _numConflictsByCategory: { [key in ConflictCategory]: number };
  private _numConflictsByHour: Array<ConflictByHour>;
  private _numStatutConflictsByHourAndStatut: Map<number, { [key in StatutConflictValue]: number }> = new Map();

  // travaux ops
  private travauxOpsMap: Map<number, TravauxOp> = new Map();

  // Indispo iti
  private indispoItiMap: Map<number, IndispoItineraire> = new Map();

  // Journees types
  private journeesTypesMap: Map<number, JourneeType> = new Map();

  // F2k
  private f2kMap: Map<string, Train[]> = new Map();

  // Compare trains
  private _numCompareTrainsByCategory: { [key in CompareTrainCategory]: number };

  // Infra props
  private sortedVaqList: VoieAQuai[]; // List of all "voies à quai" for the current station,
  // sorted by `positionGOV` prop. This is used when processing travaux ops,
  // for some ops we need the "longueur" and index of the voie.
  private govVaqList: VaqInfo[]; // Used to compute the vaqIndex of each eq
  private colorsLineByType: ColorByType[];
  private colorsTrainNumberByType: ColorByType[];
  private colorModeForLines: GovColorMode;
  private colorModeForTrainNumbers: GovColorMode;
  private listItineraires: Itineraire[];
  private listTypesMateriel: TypeMateriel[];

  // Comparison
  private tpComparison: TransportationPlanComparison;
  private passeMinuitComparison: PasseMinuitComparison;
  private compareTrainByCategoryMap: Map<CompareTrainCategory, CompareTrainByCategory> = new Map();
  private compareTrainsAddedSelected: Map<number, Partial<Train>> = new Map();
  private compareTrainsDeletedSelected: Map<number, Partial<Train>> = new Map();
  private compareTrainsChangedSelected: Map<number, Partial<TrainComparisonDTO>> = new Map();
  private compareGroupEquilibresAddedSelected: Map<number, PartialEquilibre[]> = new Map();
  private compareGroupEquilibresDeletedSelected: Map<number, PartialEquilibre[]> = new Map();
  private compareTrainsChangedSelectedPasseMinuit: Map<number, ModifiedTrain> = new Map();
  private compareEquilibresChangedSelectedPasseMinuit: Map<number, EquilibreComparisonDTO> = new Map();
  private compareAssemblagesSelectedPasseMinuit: Map<number, AssemblageDTO> = new Map();

  constructor(opts: GovDataOptions) {
    // Store strategic GovInfra info (used when creating/updating eqGroups).
    // TODO 17/11/2023 Why we have this array ? Can we replace it by govVaqList ?
    this.sortedVaqList = opts.govInfra ? _.sortBy(opts.govInfra.vaqList, ['positionGOV']) : [];
    this.govVaqList = opts.govVaqList
      ? opts.govVaqList
      : this.sortedVaqList
        ? this.sortedVaqList.map(vaq => ({ nom: vaq.nom, positions: [] }))
        : [];
    this.filteredGovVaqList = opts.filteredGovVaqList;
    this.colorsLineByType = opts.govInfra ? opts.govInfra.colorsLineByType : [];
    this.colorsTrainNumberByType = opts.govInfra ? opts.govInfra.colorsTrainNumberByType : [];
    this.colorModeForLines = opts.govInfra ? opts.govInfra.station.couleurGovTraitRef : GovColorMode.NO_COLOR;
    this.colorModeForTrainNumbers = opts.govInfra ? opts.govInfra.station.couleurGovNumeroTrainRef : GovColorMode.NO_COLOR;
    this.eqGroupHighlights = opts.eqGroupHighlights || [];
    this.listItineraires =
      opts.govInfra && opts.govInfra.station && opts.govInfra.station.infrastructure
        ? opts.govInfra.station.infrastructure.listItineraire
        : [];
    this.listTypesMateriel = opts.govInfra ? opts.govInfra.station.typesMateriel : [];

    this.codesChantier = opts.codesChantier || [];

    this.isConception = opts.isConception;

    this.journeeType = opts.journeeType;

    this.lastAppliedJtDescription = getlastAppliedJtInfo(opts.lastAppliedJt).lastAppliedJtDescription;
    this.lastAppliedJtId = getlastAppliedJtInfo(opts.lastAppliedJt).lastAppliedJtId;

    this.tpId = opts.tpId;

    this.tpRid = opts.tpRid;
    this.updateTpStatus(opts.tpStatus);
    this.isInfra2TMV = this.is2TMV(opts.govVaqList);
    (this.tpDate = moment(opts.tpDate)), (this.tpOrigine = opts.tpOrigine);
    this.parametrageId = opts.parametrageId;
    this.stationCode = opts.govInfra.station.code;
    this.updateTpModifcount(opts.modifCount !== undefined ? opts.modifCount : 0);
    this.processAndAddEqGroupDtos(
      opts.eqGroupDtos,
      opts.conflictDtos,
      undefined,
      undefined,
      opts.tpOrigine === SourcePlanTransport.GROIX_F2K,
    );
    this.processConflictDtos(opts.conflictDtos || [], opts.eqGroupDtos);
    this.processTravauxOpDtos(opts.travauxOpDtos || [], opts.conflictDtos);
    this.processIndispoItiDtos(opts.indispoItiDtos || [], opts.conflictDtos);

    this.userPreferences = opts.userPreferences;

    this.refreshHash();
    this.refreshTravauxHash({ firstTime: true });
  }

  get stats() {
    return {
      numEqGroups: this.eqGroupsMap.size,
      numConflicts: this.conflictsMap.size,
      numConflictsByCategory: this._numConflictsByCategory,
      numConflictsByHour: this._numConflictsByHour,
      numStatutConflictsByHourAndStatut: this._numStatutConflictsByHourAndStatut,
      numTravaux: this.travauxOpsMap.size + this.indispoItiMap.size,
      numTravauxApplied: this.isConception
        ? this.journeeType.operationTravauxSelections?.length + this.journeeType.indispoItineraireSelections?.length
        : undefined,
      numCompareTrainsByCategory: this._numCompareTrainsByCategory,
      numF2k: Array.from(this.f2kMap.values()).reduce((total, v) => total + v.length, 0),
    };
  }

  //
  // ----- eq & eqGroups -----
  //

  /**
   * Return the equilibre matching the given id,
   * along with its parent eqGroup.
   */
  getEquilibre(eqId: number) {
    const eqGroupId = this.getEquilibreGroupId(eqId);
    const eqGroup = this.getEqGroupById(eqGroupId);
    const eq = eqGroup && (eqGroup.find(e => e.id === eqId) as Equilibre);
    return { eq, eqGroup };
  }

  /**
   * Return the list of equilibres matching the given eqIds.
   */
  getEquilibres(eqIds: number[]) {
    return eqIds.map(eqId => this.getEquilibre(eqId).eq);
  }

  /**
   * Return the eqGroupId for the given equilibre.
   */
  getEquilibreGroupId(eqId: number) {
    return this.eqIdsToEqGroupIds.get(eqId) as string;
  }

  /**
   * Return all eqGroups in current GOV as an array.
   */
  getAllEqGroups() {
    if (this.filteredEqGroups.length > 0) {
      return this.filteredEqGroups;
    }

    return Array.from(this.eqGroupsMap.values());
  }

  /**
   * Get the list of eqGroups filtered if filter VAQ is applied.
   * @param filteredEqGroups eqGroups filtered
   */
  setFilteredEqGroups(filteredEqGroups: Equilibre[][]) {
    this.filteredEqGroups = filteredEqGroups;
  }

  /**
   * Clear the filtered eq groups
   */
  clearFilteredEqGroups() {
    this.filteredEqGroups = [];
  }

  /**
   * Return a frontend-friendly eqGroup based on its id.
   */
  getEqGroupById(eqGroupId: string) {
    return this.eqGroupsMap.get(eqGroupId) as Equilibre[];
  }

  /**
   * Get the equilibres that are on a VAQ (by vaqIndex)
   * @param vaqIndex the vaqIndex
   * @returns the equilibres
   */
  getEqGroupByVaq(vaqIndex: number): Equilibre[] {
    const eqList: Equilibre[] = [];
    this.eqGroupsMap.forEach(v => {
      const eqListFiltered: Equilibre[] = v.filter(eq => eq.$sys.vaqIndex === vaqIndex);
      if (eqListFiltered && eqListFiltered.length > 0) {
        eqList.push(...eqListFiltered);
      }
    });
    return eqList;
  }

  /**
   * Get eg group id by type materiel
   * @param typeMaterielList The type materiel list
   * @param includeNull To filter eq with TM null
   * @returns eq group id list
   */
  getEqGroupIdByTypeMateriel(typeMaterielList: TypeMateriel[], includeNull = false): string[] {
    const eqList: string[] = [];
    this.eqGroupsMap.forEach((v, k) => {
      const eqListFiltered: Equilibre[] = v.filter(eq => {
        return (includeNull && eq.typeMateriel === undefined) || typeMaterielList.find(tm => tm.type === eq.typeMateriel);
      });
      if (eqListFiltered && eqListFiltered.length === v.length) {
        eqList.push(k);
      }
    });
    return eqList;
  }

  /**
   * Creates a map of VAQ with the equilibres that are on this VAQ
   * @returns a map
   */
  computeMapVaqEtudeEquilibres(): Map<number, Equilibre[]> {
    const mapVaqEquilibres: Map<number, Equilibre[]> = new Map<number, Equilibre[]>();
    const vaqEtudeList = this.govVaqList.filter(vaq => vaq.subType === VoieSubType.ETUDE);
    for (const vaqInfo of vaqEtudeList) {
      const eqList: Equilibre[] = [];
      this.eqGroupsMap.forEach(v => {
        const eqListFiltered: Equilibre[] = v.filter(eq => eq.$sys.vaqIndex === vaqInfo.vaqIndex);
        if (eqListFiltered && eqListFiltered.length > 0) {
          eqList.push(...eqListFiltered);
        }
      });
      mapVaqEquilibres.set(vaqInfo.vaqIndex, eqList);
    }
    return mapVaqEquilibres;
  }

  /**
   * Return a frontend-friendly eqGroup based on the id of one of its eqs.
   */
  getEqGroupByEqId(eqId: number) {
    const eqGroupId = this.getEquilibreGroupId(eqId);
    return this.getEqGroupById(eqGroupId);
  }

  /**
   * Return a frontend-friendly equilibre based on its id.
   */
  getEqById(eqId: number) {
    const eqGroupId = this.getEqGroupByEqId(eqId);
    if (eqGroupId) {
      return eqGroupId.find(eq => eq.id === eqId);
    }
  }

  /**
   *
   * @param trainId The train id
   * @returns the eq containing the train
   */
  getEqByTrainId(trainId: number): Equilibre {
    let equilibre: Equilibre;
    let found = false;

    for (const eqGroup of this.getAllEqGroups()) {
      for (const eq of eqGroup) {
        if ((eq.arrive && eq.arrive.id === trainId) || (eq.depart && eq.depart.id === trainId)) {
          equilibre = eq;
          found = true;
          break;
        }
      }

      if (found) {
        break;
      }
    }
    return equilibre;
  }

  /**
   *
   * @param trainId The train id
   * @returns the eqGroup containing the train
   */
  getEqGroupByTrainId(trainId: number): Equilibre[] {
    return this.getAllEqGroups().find(eqGroup =>
      eqGroup.find(eq => (eq.arrive && eq.arrive.id === trainId) || (eq.depart && eq.depart.id === trainId)),
    );
  }

  /**
   * Mark the current GOV data as "analysable", meaning that some data has changed
   * (an eqGroup or a travauxOp has been edited) and the resulting conflicts may be impacted.
   */
  setIsAnalysable(isAnalysable: boolean) {
    this._isAnalysable = isAnalysable;
  }
  get isAnalysable() {
    return this._isAnalysable;
  }

  /**
   * Cancel drag & dropping an eqGroup by refreshing the GOV hash
   * so that the eqGroup is redrawn at its original location.
   */
  cancelDragEqGroup(eqGroupId: string) {
    this.refreshHashToRedrawEqGroups([eqGroupId], [eqGroupId]);
  }

  /**
   * Replace one or more eqGroup(s) in the GOV by one (or more) new eqGroup(s).
   *
   * This will update the redraw hash so that GovChart knows which eqGroups to redraw.
   *
   * This is used after an eqGroup was drag & dropped, or edited in the sidebar,
   * or after an equilibre was split or joined.
   *
   * @see GovStateManager#replaceEqGroups() for param info.
   */
  replaceEqGroups(eqGroupIds: string[], newEqGroupDtos: EquilibreDto[][], opts: { modifCount: number; tpComparison?: boolean }) {
    eqGroupIds.forEach(eqGroupId => this.eqGroupsMap.delete(eqGroupId)); // delete the original eqGroups
    this.processAndAddEqGroupDtos(newEqGroupDtos, undefined, opts.tpComparison ? this.tpComparison : undefined); // add the new eqGroups
    const newEqGroupIds = newEqGroupDtos.map(eqGroupDto => eqGroupGetId(eqGroupDto));
    this.refreshHashToRedrawEqGroups(eqGroupIds, newEqGroupIds); // update the hash to redraw what has changed
    this.updateTpModifcount(opts.modifCount);
  }

  // Delete the given eqGroup.
  deleteEqGroup(eqGroupId) {
    this.eqGroupsMap.delete(eqGroupId);
  }

  // Update the eqGroup highlights (changes the bgColor of the train numbers in the eqGroup).
  updateEqGroupHighlights(eqGroupHighlights: EqGroupHighlight[], opts: { modifCount: number }) {
    // First, update the `eqGroupHighlights` props.
    this.eqGroupHighlights = eqGroupHighlights;
    // Then, force the re-creation and re-drawing of the eqGroups affected by those highlights.
    const eqGroupIds = eqGroupHighlights.map(hl => hl.eqGroupId);
    const eqGroupDtos = eqGroupIds
      .map(eqGroupId => this.getEqGroupById(eqGroupId))
      .map(eqGroup => eqGroup.map(eq => createDtoFromEquilibre(eq)));
    this.replaceEqGroups(eqGroupIds, eqGroupDtos, opts);
  }

  /**
   * Update the entire "transportation plan" info in GovData,
   * i.e. the TP status, the list of equilibres...
   *
   * This is typically called after the GOV was certified or uncertified,
   * when all ids in the GOV have changed, amongst other things.
   */
  updateTpInfo(opts: {
    tpId: number;
    tpStatus: TransportationPlanStatus;
    eqGroupDtos: EquilibreDto[][];
    modifCount: number;
    conflictDtos?: ConflictDto[];
    lastAppliedJt?: string;
    tpComparison?: TransportationPlanComparison;
    passeMinuitComparison?: PasseMinuitComparisonDTO;
  }): this {
    // console.log(`[GovData] updateTpInfo()`, opts);

    this.tpId = opts.tpId;
    this.updateTpStatus(opts.tpStatus);
    this.lastAppliedJtDescription = getlastAppliedJtInfo(opts.lastAppliedJt).lastAppliedJtDescription;
    this.lastAppliedJtId = getlastAppliedJtInfo(opts.lastAppliedJt).lastAppliedJtId;

    // Clear existing eqGroups to recreate the entire Map.
    this.eqGroupsMap.clear();
    this.processAndAddEqGroupDtos(opts.eqGroupDtos, opts.conflictDtos, opts.tpComparison, opts.passeMinuitComparison);

    // Update conflicts
    if (opts.conflictDtos) {
      this.processConflictDtos(opts.conflictDtos, opts.eqGroupDtos);
      this.updateConflictRelatedProps(opts.conflictDtos);
    } else {
      // conflicts haven't changed, but we must recompute the `Conflict.eqGroupIds` property.
      this.processConflictDtos(this.getAllConflicts(), opts.eqGroupDtos);
    }

    this.processComparison(opts.tpComparison, opts.passeMinuitComparison);

    this.updateTpModifcount(opts.modifCount);

    this.refreshHash(); // will trigger GOV redraw

    return this;
  }

  private updateTpModifcount(modifCount: number) {
    this.modifCount = modifCount;
    this.setIsAnalysable(modifCount > 0 ? true : false);
  }

  private updateTpStatus(tpStatus: TransportationPlanStatus) {
    this.tpStatus = tpStatus;
    this.isGovCertified = this.tpStatus === TransportationPlanStatus.CERTIFIED;
    this.isGovJTValidated = this.tpStatus === TransportationPlanStatus.JT_VALIDATED;
    this.isGovSynchronized =
      this.tpStatus === TransportationPlanStatus.SYNCHRO_MERGED_HOUAT ||
      this.tpStatus === TransportationPlanStatus.SYNCHRO_MERGED_PASSE_MINUIT;
    this.isGovSynchronizedHouat = this.tpStatus === TransportationPlanStatus.SYNCHRO_MERGED_HOUAT;
    this.isGovSynchronizedPasseMinuit = this.tpStatus === TransportationPlanStatus.SYNCHRO_MERGED_PASSE_MINUIT;
    this.isGovNotEditable = this.isGovCertified || this.isGovJTValidated || this.isGovSynchronized;
  }

  // This is used for the PDF export in PageGovComponent.
  getTransportationPlanDto(): TransportationPlanDto {
    const tpDto: TransportationPlanDto = {
      id: this.tpId,
      parametrageId: this.parametrageId,
      date: this.tpRid,
      etat: this.tpStatus,
      modificationCount: this.modifCount,
      codeGare: this.stationCode,
      equilibresLists: this.getAllEqGroups().map(eqGroup => eqGroup.map(eq => createDtoFromEquilibre(eq))),
      origine: null, // missing
      rid: this.tpRid,
      lastModifiedDate: null, // missing
      lastAppliedJt: null,
    };
    return tpDto;
  }

  /**
   * Return all equilibres (ids only) matching the given train number.
   *
   * The search is NOT case-sensitive.
   */
  searchEqsByTrainNumber(searchedTrain: string): number[] {
    searchedTrain = searchedTrain.toLowerCase();
    const foundEqs: number[] = [];
    this.eqGroupsMap.forEach(eqGroup =>
      eqGroup.forEach(eq => {
        if (eqContainsTrainNumber(eq, searchedTrain)) {
          foundEqs.push(eq.id);
        }
      }),
    );
    return foundEqs;
  }

  /**
   * return array Trains with second itinerary
   */
  searchTrainWithItiSecond(): { eqId: number; train: Train }[] {
    const trains: { eqId: number; train: Train }[] = [];
    for (const eqs of this.eqGroupsMap.values()) {
      for (const eq of eqs) {
        if (eq.$sys.arriveIti && eq.$sys.arriveIti.privilege >= 2) {
          trains.push({ eqId: eq.id, train: eq.arrive });
        }
        if (eq.$sys.departIti && eq.$sys.departIti.privilege >= 2) {
          trains.push({ eqId: eq.id, train: eq.depart });
        }
      }
    }
    trains.sort((a, b) => {
      const tA = a.train.dateHeure ? new Date(a.train.dateHeure).getTime() : 0;
      const tB = b.train.dateHeure ? new Date(b.train.dateHeure).getTime() : 0;
      return tA - tB > 0 ? 1 : -1;
    });

    return trains;
  }

  /**
   * Convert the given eqGroupDtos into frontend-friendly eqGroups
   * and index them by eqGroupId.
   *
   * This function is CUMULATIVE. It DOES NOT RESET the eqGroups
   * that were previously processed and added to the map.
   */
  private processAndAddEqGroupDtos(
    eqGroupDtos: EquilibreDto[][],
    conflictDtos?: (ConflictDto | Conflict)[],
    tpComparison?: TransportationPlanComparison,
    passeMinuitComparison?: PasseMinuitComparisonDTO,
    isF2k = false,
  ) {
    // If no conflicts are provided, reuse existing conflicts.
    conflictDtos = _.isEmpty(conflictDtos) ? this.getAllConflicts() : conflictDtos;

    eqGroupDtos.forEach(eqGroupDto => {
      const eqGroupId = eqGroupGetId(eqGroupDto);
      const eqGroup = eqGroupDto.map(eqDto =>
        createEquilibreFromDto(eqDto, this.govVaqList, eqGroupDto, conflictDtos, {
          colorsLineByType: this.colorsLineByType,
          colorModeForLines: this.colorModeForLines,
          colorsTrainNumberByType: this.colorsTrainNumberByType,
          colorModeForTrainNumbers: this.colorModeForTrainNumbers,
          eqGroupHighlights: this.eqGroupHighlights,
          listItineraires: this.listItineraires,
          listTypesMateriel: this.listTypesMateriel,
          stationCode: this.stationCode,
          tpComparison,
          passeMinuitComparison,
        }),
      );
      // Only keep eqGroups with a valid VAQ
      if (eqGroup[0].$sys.vaqIndex !== -1) {
        this.eqGroupsMap.set(eqGroupId, eqGroup);
        eqGroupDto.forEach(eqDto => {
          this.eqIdsToEqGroupIds.set(eqDto.id, eqGroupId); // { eqId => eqGroupId } index
          if (isF2k) {
            this.processF2kTrainsFromEq(eqDto);
          }
        });
      }
    });
  }

  /**
   * Update the GovData hash with a value indicating
   * which eqGroup(s) should be removed from and/or added to the chart.
   *
   * @param oldEqGroupIds The old eqGroups that were removed or updated.
   * @param newEqGroupIds The new eqGroups that were added or updated.
   */
  private refreshHashToRedrawEqGroups(oldEqGroupIds: string[], newEqGroupIds: string[]) {
    this.refreshHash(`__redraw:${JSON.stringify({ oldEqGroupIds, newEqGroupIds })}`);
  }

  /**
   * Compute a new hash to indicate that one or several properties in GovData have changed,
   * typically after an eqGroup was drag & dropped or an eq was edited in the sidebar,
   * or a travaux op was modified.
   *
   * The hash is used to trigger a full redraw of the GOV's SVG elements.
   *
   * Note. The hash is not based on the actual data stored in GovData,
   * it's just a uniqid() that's recreated whenever the data changes.
   *
   * @param str Optional string of characters to append to the hash.
   */
  private refreshHash(str?: string) {
    const date = new Date();
    const timestamp = date.getTime();
    this.hash = 'hash-' + `${timestamp}`.substring(-6) + (str || '');
  }

  //
  // ----- Conflicts -----
  //

  getAllConflicts() {
    return Array.from(this.conflictsMap.values());
  }

  /**
   * Update the list of all conflicts (typically called after a travauxOp has changed).
   */
  updateAllConflictDtos(conflictDtos: ConflictDto[]) {
    const eqGroupDtos = this.getAllEqGroups().map(eqGroup => eqGroup.map(eq => createDtoFromEquilibre(eq)));
    this.processConflictDtos(conflictDtos, eqGroupDtos);
    this.updateConflictRelatedProps(conflictDtos);
  }

  /**
   * Update the statut conflict dto on conflicts
   * @param statutConflictDto The statut conflict dto
   */
  updateStatutConflictDto(statutConflictDto: StatutConflictDto) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [key, value] of this.conflictsMap.entries()) {
      if (value.statut && value.statut.id === statutConflictDto.id) {
        value.statut = createStatutConflict(statutConflictDto);
        break;
      }
    }
  }

  /**
   * Update the number of statut conflicts by hour and statut
   */
  updateNumStatutConflicts() {
    for (let i = 0; i <= 23; i++) {
      this.initNumStatutConflicts(i);
    }

    this.conflictsMap.forEach(conflict => {
      if (conflict.statut && conflict.hourAffected !== -1) {
        this._numStatutConflictsByHourAndStatut.get(conflict.hourAffected)[conflict.statut.value]++;
      }
    });
  }

  // Update the conflict-related props in the eqGroups and the travaux ops and the indispo iti
  private updateConflictRelatedProps(conflictDtos: ConflictDto[]) {
    // Update the `$sys.conflictIds` property in each eq of each eqGroup.
    this.eqGroupsMap.forEach((eqGroup, eqGroupId) => {
      const updatedEqGroup = eqGroup.map(eq => ({
        ...eq,
        $sys: {
          ...eq.$sys,
          conflictIds: eqGetConflictIds(eq, conflictDtos),
          incoherenceDonneesIds: eqGetConflictIncoherenceDonneesIds(eq, conflictDtos),
        },
      }));
      this.eqGroupsMap.set(eqGroupId, updatedEqGroup);
    });
    // Update the `$sys.conflictIds` property in each travaux op.
    this.travauxOpsMap.forEach((travauxOp, travauxOpId) => {
      const updatedTravauxOp = { ...travauxOp, $sys: { ...travauxOp.$sys, conflictIds: travauxOpGetConflictIds(travauxOp, conflictDtos) } };
      this.travauxOpsMap.set(travauxOpId, updatedTravauxOp);
    });

    this.indispoItiMap.forEach((indispoIti, indispoItiId) => {
      const updatedIndispoIti = {
        ...indispoIti,
        $sys: { ...indispoIti.$sys, conflictsIds: indispoItiGetConflictIds(indispoIti, conflictDtos) },
      };
      this.indispoItiMap.set(indispoItiId, updatedIndispoIti);
    });
  }

  /**
   * Return a frontend-friendly conflict based on its id
   * and re-hydrate the eqGroups implicated in the conflict.
   */
  getConflictById(conflictId: number) {
    const conflict = this.conflictsMap.get(conflictId);
    if (conflict) {
      // convert eqGroupIds to eqGroups
      conflict.eqGroups = conflict.eqGroupIds.map(eqGroupId => this.getEqGroupById(eqGroupId));
    }
    return conflict as Conflict;
  }

  /**
   * Return a list of conflicts (ids) for the given category.
   */
  getConflictIdsByCategory(category: ConflictCategory) {
    return this.getAllConflicts().filter(c => c.category === category);
  }

  /**
   * Return the current GOV's conflicts filtered by category and hourRange.
   */
  getFilteredConflicts(filters: Partial<ConflictFilters> = {}): Conflict[] {
    let filteredConflicts: Conflict[] = this.getAllConflicts();

    if (filters.category) {
      filteredConflicts = filteredConflicts.filter(c => c.category === filters.category);
    }

    if (filters.hourRange) {
      // keep a conflict if the hour it affects is within the current time range
      const range = filters.hourRange;
      filteredConflicts = filteredConflicts.filter(c => c.hourAffected >= range.hourStart && c.hourAffected <= range.hourEnd);
    }

    return filteredConflicts;
  }

  /**
   * Return the current GOV's conflicts filtered by status.
   */
  getFilteredConflictsByStatus(filteredConflicts: Conflict[], filters: Partial<ConflictFilters> = {}): Conflict[] {
    if (filters.statuts) {
      return filteredConflicts.filter(c => c.statut && filters.statuts.find(s => (s as StatutConflictValue) === c.statut.value));
    } else {
      return filteredConflicts;
    }
  }

  /**
   * Return all conflicts related to the given equilibre.
   */
  getConflictsForEq(equilibre: Equilibre) {
    return equilibre.$sys.conflictIds.map(conflictId => this.getConflictById(conflictId));
  }

  /**
   * Return all incoherence de données related to the given equilibre.
   */
  getIncoherenceDonneesForEq(equilibre: Equilibre) {
    return equilibre.$sys.incoherenceDonneesIds.map(conflictId => this.getConflictById(conflictId));
  }

  /**
   * Return all conflicts related to the given type of travaux ops.
   */
  getConflictsForTravauxType(travauxType: TravauxOpType) {
    let conflictIds: number[] = [];
    if (travauxType === TravauxOpType.INDISPO_ITI) {
      this.indispoItiMap.forEach(indispoIti => {
        conflictIds = conflictIds.concat(indispoIti.$sys.conflictIds);
      });
    } else {
      // VEG / ZEP / SEL
      this.travauxOpsMap.forEach(travauxOp => {
        if (travauxOp.typeTravaux === travauxType) {
          conflictIds = conflictIds.concat(travauxOp.$sys.conflictIds);
        }
      });
    }

    return conflictIds.map(conflictId => this.getConflictById(conflictId));
  }

  /**
   * Return all conflicts related to the given travaux op.
   */
  getConflictsForTravauxOp(travauxOp: TravauxOp) {
    return travauxOp.$sys.conflictIds.map(conflictId => this.getConflictById(conflictId));
  }

  /**
   * Return all conflicts related to the given indispo iti.
   */
  getConflictsForIndispoIti(indispoIti: IndispoItineraire) {
    return indispoIti.$sys.conflictIds.map(conflictId => this.getConflictById(conflictId));
  }

  /**
   * Return true if the given eq is in the given conflict.
   */
  isEqInConflict(eqId: number, conflictId: number) {
    const conflict = this.getConflictById(conflictId);
    return conflict ? conflict.eqGroups.some(eqGroup => eqGroup.some(eq => eq.id === eqId)) : false;
  }

  /**
   * Return true if the given eqGroup is in the given conflict.
   */
  isEqGroupInConflict(eqGroupId: string, conflictId: number) {
    const conflict = this.getConflictById(conflictId);
    const eqGroupIds = conflict.eqGroups.map(eqGroup => eqGroupGetId(eqGroup));
    return eqGroupIds.includes(eqGroupId);
  }

  /**
   * Convert the given conflictDtos into frontend-friendly conflicts
   * and create multiple indexes (conflict by id, num conflicts by category...).
   *
   * ATTENTION. This fonction RESETS the conflict list and count
   * every time it is invoked.
   */
  private processConflictDtos(conflictDtos: (ConflictDto | Conflict)[], eqGroupDtos: EquilibreDto[][]) {
    this.conflictsMap.clear();
    conflictDtos.forEach(conflict => {
      const conflictId = isConflictDto(conflict) ? conflict.identifier : conflict.id;
      this.conflictsMap.set(conflictId, createConflictFromDto(conflict, { eqGroupDtos }));
    });
    // Count conflicts by category and by hour.
    this._numConflictsByCategory = {
      [ConflictCategory.GENERAL]: 0,
      [ConflictCategory.INCOHERENCE_DONNEES]: 0,
      [ConflictCategory.TRAIN_NON_PLACE]: 0,
    };
    this._numConflictsByHour = [];
    for (let i = 0; i <= 23; i++) {
      this._numConflictsByHour.push({ hour: i, conflictCount: 0 });
      this.initNumStatutConflicts(i);
    }

    this.conflictsMap.forEach(conflict => {
      this._numConflictsByCategory[conflict.category]++;
      if (conflict.statut && conflict.hourAffected !== -1) {
        this._numStatutConflictsByHourAndStatut.get(conflict.hourAffected)[conflict.statut.value]++;
      }
      // Only count "general" conflicts which have an "affected hour".
      if (conflict.category === ConflictCategory.GENERAL && conflict.hourAffected !== -1) {
        this._numConflictsByHour[conflict.hourAffected].conflictCount++;
      }
    });
  }

  private initNumStatutConflicts(key: number) {
    this._numStatutConflictsByHourAndStatut.set(key, {
      [StatutConflictValue.NON_TRAITE]: 0,
      [StatutConflictValue.EN_COURS]: 0,
      [StatutConflictValue.MAINTENU]: 0,
      [StatutConflictValue.FAUX_CONFLIT]: 0,
    });
  }

  //
  // ----- Travaux -----
  //

  getAllTravauxOps() {
    return Array.from(this.travauxOpsMap.values());
  }

  getAllIndisposItis() {
    return Array.from(this.indispoItiMap.values());
  }

  /**
   * Return a frontend-friendly travaux op based on its id.
   */
  getTravauxOpById(travauxOpId: number) {
    const travauxOp = this.travauxOpsMap.get(travauxOpId);
    return travauxOp as TravauxOp;
  }

  getJourneeTypeById(journeeTypeId: number): JourneeType {
    return this.journeesTypesMap.get(journeeTypeId);
  }

  /**
   * Return a frontend-friendly indispo iti based on its id.
   */
  getIndispoItiById(indispoItiId: number) {
    const indispoIti = this.indispoItiMap.get(indispoItiId);
    return indispoIti as IndispoItineraire;
  }

  /**
   * Update the list of all travaux ops after an op was edited or deleted.
   *
   * ATTENTION. This fonction RESETS the travaux list and count every time it is invoked.
   *
   * @param travauxOpDtos The new list of travaux ops.
   */
  updateAllTravauxOpDtos(travauxOpDtos: TravauxOpDto[]) {
    this.processTravauxOpDtos(travauxOpDtos);
    this.refreshTravauxHash(); // will trigger redraw of travaux ops
  }

  updateAllIndispoItiDtos(indispoItiDtos: IndispoItineraireDTO[]) {
    this.processIndispoItiDtos(indispoItiDtos);
  }

  /**
   * Convert the given travaux op DTOs into frontend-friendly equivalents
   * and create a Map of all travaux ops.
   *
   * @param travauxOpDtos Travaux Op DTOs from the backend.
   * @param conflictDtos  Conflict DTOs from the backend (to determine which travauxOp is implicated in a conflict).
   *                      If no conflicts are provided, reuse existing conflicts.
   */
  private processTravauxOpDtos(travauxOpDtos: TravauxOpSelection[], conflictDtos?: ConflictDto[]) {
    conflictDtos = conflictDtos || this.getAllConflicts().map(conflict => createDtoFromConflict(conflict));

    const jtTravauxOpsSelection = this.journeeType ? this.journeeType.operationTravauxSelections : [];

    this.travauxOpsMap.clear();
    travauxOpDtos.forEach(travauxOp => {
      this.travauxOpsMap.set(
        travauxOp.id,
        createTravauxOpFromDto(travauxOp, { sortedVaqList: this.sortedVaqList, conflictDtos, jtTravauxOpsSelection }),
      );
    });
  }

  /**
   * Convert the given indispos itis DTOs into frontend-friendly equivalents
   * and create a Map of all indispos itis.
   *
   * @param IndispoItiDtos indispos itis DTOs from the backend.
   * @param conflictDtos  Conflict DTOs from the backend (to determine which indispo iti is implicated in a conflict).
   *                      If no conflicts are provided, reuse existing conflicts.
   */
  private processIndispoItiDtos(IndispoItiDtos: IndispoItineraireDTO[], conflictDtos?: ConflictDto[]) {
    conflictDtos = conflictDtos || this.getAllConflicts().map(conflict => createDtoFromConflict(conflict));

    const jtIndispoItiSelections = this.journeeType ? this.journeeType.indispoItineraireSelections : [];

    this.indispoItiMap.clear();
    IndispoItiDtos.forEach(indispoIti => {
      this.indispoItiMap.set(indispoIti.id, createIndispoItiFromDto(indispoIti, { conflictDtos, jtIndispoItiSelections }));
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private refreshTravauxHash(opts = { firstTime: false }) {
    const date = new Date();
    const timestamp = date.getTime();
    this.travauxHash = 'travauxHash-' + `${timestamp}`.substring(-6);
  }

  //
  // ----- Journees types -----
  //

  /**
   * Get the journees types
   * @returns List of journees types
   */
  getAllJourneesTypes(): JourneeType[] {
    return Array.from(this.journeesTypesMap.values());
  }

  /**
   * Update journees types
   * @param journeesTypesList The journees types list
   */
  updateJourneesTypes(journeesTypesList: JourneeType[]) {
    this.processJourneesTypes(journeesTypesList);
  }

  /**
   * Create map of journees types
   * @param journeesTypes List of journees types
   */
  private processJourneesTypes(journeesTypes: JourneeType[]) {
    this.journeesTypesMap.clear();
    journeesTypes.forEach(jt => {
      this.journeesTypesMap.set(jt.id, jt);
    });
  }

  /**
   * Update gov journee type
   * @param journeeType The journee type
   */
  updateJourneeType(journeeType: JourneeType) {
    this.journeeType = journeeType;
  }

  //
  // ----- Compare -----
  //

  /**
   * Process comparison for HOUAT and Passe-minuit.
   * @param tpComparison The transportation plan comparison
   * @param passeMinuitComparison The passe minuit comparison
   */
  processComparison(tpComparison: TransportationPlanComparison, passeMinuitComparison: PasseMinuitComparisonDTO) {
    this.resetCompareMaps();

    if (tpComparison) {
      this.processTpComparison(tpComparison);
    } else if (passeMinuitComparison) {
      this.processPasseMinuitComparison(passeMinuitComparison);
    }

    this.updateNumCompareElementsByCategory();
  }

  /**
   * Process the tp comparison
   * @param tpComparison The tp comparison
   */
  processTpComparison(tpComparison: TransportationPlanComparison) {
    this.tpComparison = tpComparison;

    this.processCompareElementsByCategory(
      this.tpComparison.addedTrains,
      this.tpComparison.deletedTrains,
      this.tpComparison.modifiedTrains,
      [],
      [],
    );
  }

  /**
   * Process the passe minuit comparison
   * @param passeMinuitComparisonDTO The passe minuit comparison
   */
  processPasseMinuitComparison(passeMinuitComparisonDTO: PasseMinuitComparisonDTO) {
    this.passeMinuitComparison = createPasseMinuitComparisonFromDto(passeMinuitComparisonDTO, this.govVaqList);

    this.processCompareElementsByCategory(
      this.passeMinuitComparison.addedMrs,
      this.passeMinuitComparison.deletedMrs,
      this.passeMinuitComparison.modifiedTrains,
      this.passeMinuitComparison.modifiedMrs,
      this.passeMinuitComparison.modifiedGroups,
    );
  }

  /**
   * Process compare elements by category
   */
  private processCompareElementsByCategory(
    addedElements: Train[] | Equilibre[][],
    deletedElements: Train[] | Equilibre[][],
    modifiedTrains: TrainComparisonDTO[],
    modifiedMrs: EquilibreComparison[],
    modifiedGroups: Assemblage[],
  ) {
    ////////////// TRAINS ADDED //////////////
    const compareTrainByCategoryAdded: CompareTrainByCategory = {
      category: CompareTrainCategory.TRAINS_ADDED,
      compareTrainsBySubCategory: [
        {
          subCategory: CompareTrainSubCategory.TRAINS_ADDED,
          title: 'Trains ajoutés',
          compareElements: this.createCompareElements(addedElements, CompareTrainCategory.TRAINS_ADDED),
        },
      ],
    };
    this.compareTrainByCategoryMap.set(CompareTrainCategory.TRAINS_ADDED, compareTrainByCategoryAdded);

    ////////////// TRAINS DELETED //////////////

    const compareTrainByCategoryDeleted: CompareTrainByCategory = {
      category: CompareTrainCategory.TRAINS_DELETED,
      compareTrainsBySubCategory: [
        {
          subCategory: CompareTrainSubCategory.TRAINS_DELETED,
          title: 'Trains supprimés',
          compareElements: this.createCompareElements(deletedElements, CompareTrainCategory.TRAINS_DELETED),
        },
      ],
    };
    this.compareTrainByCategoryMap.set(CompareTrainCategory.TRAINS_DELETED, compareTrainByCategoryDeleted);

    ////////////// TRAINS CHANGED //////////////

    let compareTrainsBySubCategoryModified: CompareTrainBySubCategory[] = [];
    compareTrainsBySubCategoryModified = [
      {
        subCategory: CompareTrainSubCategory.MODIFICATION_HORAIRE,
        title: "Modification d'horaire",
        compareElements: this.convertTrainsCompareToCompareElements(modifiedTrains, CompareTrainSubCategory.MODIFICATION_HORAIRE),
      },
      {
        subCategory: CompareTrainSubCategory.MODIFICATION_VEL,
        title: 'Modification de voie en ligne',
        compareElements: this.convertTrainsCompareToCompareElements(modifiedTrains, CompareTrainSubCategory.MODIFICATION_VEL),
      },
      {
        subCategory: CompareTrainSubCategory.MODIFICATION_OD,
        title: 'Modification des O/D',
        compareElements: this.convertTrainsCompareToCompareElements(modifiedTrains, CompareTrainSubCategory.MODIFICATION_OD),
      },
      {
        subCategory: CompareTrainSubCategory.MODIFICATION_VAQ,
        title: 'Modification de voie à quai',
        compareElements: this.convertTrainsCompareToCompareElements(modifiedTrains, CompareTrainSubCategory.MODIFICATION_VAQ),
      },
    ];

    if (this.isGovSynchronizedPasseMinuit) {
      compareTrainsBySubCategoryModified.push({
        subCategory: CompareTrainSubCategory.MODIFICATION_TC,
        title: 'Modification de type circulation',
        compareElements: this.convertTrainsCompareToCompareElements(modifiedTrains, CompareTrainSubCategory.MODIFICATION_TC),
      });

      compareTrainsBySubCategoryModified.push({
        subCategory: CompareTrainSubCategory.MODIFICATION_ITINERAIRE,
        title: "Modification de l'itinéraire",
        compareElements: this.convertTrainsCompareToCompareElements(modifiedTrains, CompareTrainSubCategory.MODIFICATION_ITINERAIRE),
      });
    }

    const compareTrainByCategoryModified: CompareTrainByCategory = {
      category: CompareTrainCategory.TRAINS_MODIFIED,
      compareTrainsBySubCategory: compareTrainsBySubCategoryModified,
    };
    this.compareTrainByCategoryMap.set(CompareTrainCategory.TRAINS_MODIFIED, compareTrainByCategoryModified);

    if (this.isGovSynchronizedPasseMinuit) {
      ////////////// EQUILIBRES CHANGED //////////////

      let compareEquilibresBySubCategoryModified: CompareTrainBySubCategory[] = [];
      compareEquilibresBySubCategoryModified = [
        {
          subCategory: CompareTrainSubCategory.MODIFICATION_TYPE_MATERIEL,
          title: 'Modification de type matériel',
          compareElements: this.convertEquilibresCompareToCompareElements(modifiedMrs, CompareTrainSubCategory.MODIFICATION_TYPE_MATERIEL),
        },
        {
          subCategory: CompareTrainSubCategory.MODIFICATION_NB_ELEMENTS,
          title: 'Modification des éléments',
          compareElements: this.convertEquilibresCompareToCompareElements(modifiedMrs, CompareTrainSubCategory.MODIFICATION_NB_ELEMENTS),
        },
        {
          subCategory: CompareTrainSubCategory.MODIFICATION_OCCUPATION,
          title: `Modification de l'occupation`,
          compareElements: this.convertEquilibresCompareToCompareElements(modifiedMrs, CompareTrainSubCategory.MODIFICATION_OCCUPATION),
        },
      ];

      const compareEquilibreByCategoryModified: CompareTrainByCategory = {
        category: CompareTrainCategory.EQS_MODIFIED,
        compareTrainsBySubCategory: compareEquilibresBySubCategoryModified,
      };

      this.compareTrainByCategoryMap.set(CompareTrainCategory.EQS_MODIFIED, compareEquilibreByCategoryModified);

      ////////////// ASSEMBLAGES CHANGED //////////////

      const compareTrainByCategoryAssemblagesModified: CompareTrainByCategory = {
        category: CompareTrainCategory.ASSEMBLAGES_MODIFIED,
        compareTrainsBySubCategory: [
          {
            subCategory: CompareTrainSubCategory.ASSEMBLAGES_MODIFIED,
            title: 'Assemblages modifiés',
            compareElements: this.createCompareElementsFromAssemblages(modifiedGroups, CompareTrainCategory.ASSEMBLAGES_MODIFIED),
          },
        ],
      };
      this.compareTrainByCategoryMap.set(CompareTrainCategory.ASSEMBLAGES_MODIFIED, compareTrainByCategoryAssemblagesModified);
    }
  }

  /**
   *
   * @param elements The elements which are trains or equilibres
   * @param compareTrainCategory The compare train category (TRAINS ADDED or DELETED)
   * @returns A list of compare elements
   */
  private createCompareElements(elements: Train[] | Equilibre[][], compareTrainCategory: CompareTrainCategory): CompareElement[] {
    const compareElements: CompareElement[] = [];
    const copyElements: Train[] | Equilibre[][] = _.cloneDeep(elements);

    copyElements.forEach((element: Train | Equilibre[]) => {
      let compareElement: CompareElement;
      if (!('numero' in element)) {
        // Equilibre
        const equilibre = element[0] as Equilibre;
        const groupEquilibre = element;
        compareElement = {
          compareType: compareTrainCategory,
          elementType: CompareElementType.EQUILIBRE,
          compareEquilibre: {
            arrivee: createCompareTrain(TrainTypeLabel.ARRIVEE, [equilibre?.arrive], compareTrainCategory),
            depart: createCompareTrain(TrainTypeLabel.DEPART, [equilibre?.depart], compareTrainCategory),
            voieAQuai: equilibre.arrive ? equilibre.arrive.voieFin : equilibre.depart.voieDebut,
            equilibre: equilibre,
            groupEquilibre: groupEquilibre,
          },
          compareTrain: undefined,
          compareAssemblage: undefined,
          state: CompareTrainState.NONE,
        };
      } else {
        // Train
        const train = element as Train;
        if (train.dePassage) {
          let otherTrainIndex: number;

          const otherTrain = (copyElements as Array<Train>).find((passage: Train, i: number) => {
            if (passage.id !== train.id) {
              if (train.numero === passage.numero) {
                otherTrainIndex = i;
                return true;
              } else if (train.sens === Sens.ARRIVEE && eqDoesTrainPariteChange({ arrive: train, depart: passage })) {
                otherTrainIndex = i;
                return true;
              } else if (train.sens === Sens.DEPART && eqDoesTrainPariteChange({ arrive: passage, depart: train })) {
                otherTrainIndex = i;
                return true;
              }
            }

            return false;
          });

          if (!otherTrain) {
            console.error(`[GovData] createCompareElements : Did not find the other train for passage ${train.numero}`);
          }

          const trains = train.sens === Sens.ARRIVEE ? [train, otherTrain] : [otherTrain, train];
          compareElement = {
            compareType: compareTrainCategory,
            elementType: CompareElementType.TRAIN,
            compareTrain: createCompareTrain(TrainTypeLabel.PASSAGE, trains, compareTrainCategory),
            compareEquilibre: undefined,
            compareAssemblage: undefined,
            state: CompareTrainState.NONE,
          };

          copyElements.splice(otherTrainIndex, 1);
        } else {
          compareElement = {
            compareType: compareTrainCategory,
            elementType: CompareElementType.TRAIN,
            compareTrain: createCompareTrain(
              train.sens === Sens.ARRIVEE ? TrainTypeLabel.ARRIVEE : TrainTypeLabel.DEPART,
              [train],
              compareTrainCategory,
            ),
            compareEquilibre: undefined,
            compareAssemblage: undefined,
            state: CompareTrainState.NONE,
          };
        }
      }

      compareElements.push(compareElement);
    });

    return compareElements;
  }

  private createCompareElementsFromAssemblages(elements: Assemblage[], compareTrainCategory: CompareTrainCategory): CompareElement[] {
    const compareElements: CompareElement[] = [];
    const copyElements: Assemblage[] = _.cloneDeep(elements);

    copyElements.forEach((element: Assemblage) => {
      const compareElement = {
        compareType: compareTrainCategory,
        elementType: CompareElementType.ASSEMBLAGE,
        compareAssemblage: element,
        compareTrain: undefined,
        compareEquilibre: undefined,
        state: CompareTrainState.NONE,
      };
      compareElements.push(compareElement);
    });
    return compareElements;
  }
  /**
   * Convert list of train comparisons to list of compare elements
   * @param trainComparisonList The list of trains
   * @param compareTrainBySubCategory The compare train by sub category
   * @returns a list of compare elements
   */
  private convertTrainsCompareToCompareElements(
    trainComparisonList: TrainComparisonDTO[],
    compareTrainBySubCategory: CompareTrainSubCategory,
  ): CompareElement[] {
    let trainsFiltered: TrainComparisonDTO[] = [];
    let compareElements: CompareElement[] = [];
    switch (compareTrainBySubCategory) {
      case CompareTrainSubCategory.MODIFICATION_HORAIRE:
        trainsFiltered = trainComparisonList.filter(t => t.modifiedFields.find(mf => mf === ModifiedField.HORAIRE));
        break;
      case CompareTrainSubCategory.MODIFICATION_VAQ:
        trainsFiltered = trainComparisonList.filter(t => t.modifiedFields.find(mf => mf === ModifiedField.VOIE_A_QUAI));
        break;
      case CompareTrainSubCategory.MODIFICATION_TC:
        trainsFiltered = trainComparisonList.filter(t => t.modifiedFields.find(mf => mf === ModifiedField.TYPE_CIRCULATION));
        break;
      case CompareTrainSubCategory.MODIFICATION_VEL:
        trainsFiltered = trainComparisonList.filter(t => t.modifiedFields.find(mf => mf === ModifiedField.VOIE_EN_LIGNE));
        break;
      case CompareTrainSubCategory.MODIFICATION_ITINERAIRE:
        trainsFiltered = trainComparisonList.filter(t => t.modifiedFields.find(mf => mf === ModifiedField.ITINERAIRE));
        break;
      case CompareTrainSubCategory.MODIFICATION_OD:
        trainsFiltered = trainComparisonList.filter(t =>
          t.modifiedFields.find(mf => mf === ModifiedField.ORIGINE || mf === ModifiedField.DESTINATION),
        );
        break;
      default:
        console.error(`[GovData] convertTrainsCompareToCompareTrains : The sub category ${compareTrainBySubCategory} does not exist.`);
    }

    compareElements = trainsFiltered.map(train => {
      return {
        elementType: 'Train',
        compareType: CompareTrainCategory.TRAINS_MODIFIED,
        compareTrain: createCompareTrain(
          train.refTrain.sens === Sens.ARRIVEE ? TrainTypeLabel.ARRIVEE : TrainTypeLabel.DEPART,
          [train.refTrain, train.otherTrain],
          CompareTrainCategory.TRAINS_MODIFIED,
          compareTrainBySubCategory,
          this.getModifiedFieldValue(train.refTrain, compareTrainBySubCategory),
          this.getModifiedFieldValue(train.otherTrain, compareTrainBySubCategory),
        ),
        compareEquilibre: undefined,
        state: CompareTrainState.NONE,
      } as CompareElement;
    });

    return compareElements;
  }

  /**
   * Convert list of eq comparisons to list of compare elements
   * @param equilibreComparisonList The list of eq
   * @param compareEquilibreBySubCategory The compare train by sub category
   * @returns a list of compare elements
   */
  private convertEquilibresCompareToCompareElements(
    equilibreComparisonList: EquilibreComparison[],
    compareEquilibreBySubCategory: CompareTrainSubCategory,
  ): CompareElement[] {
    let eqFiltered: EquilibreComparison[] = [];
    switch (compareEquilibreBySubCategory) {
      case CompareTrainSubCategory.MODIFICATION_NB_ELEMENTS:
        eqFiltered = equilibreComparisonList.filter(t => t.modifiedFields.find(mf => mf === ModifiedField.NB_ELEMENTS));
        break;
      case CompareTrainSubCategory.MODIFICATION_TYPE_MATERIEL:
        eqFiltered = equilibreComparisonList.filter(t => t.modifiedFields.find(mf => mf === ModifiedField.TYPE_MATERIEL));
        break;
      case CompareTrainSubCategory.MODIFICATION_OCCUPATION:
        eqFiltered = equilibreComparisonList.filter(t => t.modifiedFields.find(mf => mf === ModifiedField.MI_QUAI));
        break;
      default:
        console.error(
          `[GovData] convertEquilibresCompareToCompareTrains : The sub category ${compareEquilibreBySubCategory} does not exist.`,
        );
    }

    const compareElements: CompareElement[] = eqFiltered.map(eq => {
      return {
        elementType: CompareElementType.EQUILIBRE,
        compareTrain: undefined,
        compareType: CompareTrainCategory.EQS_MODIFIED,
        compareEquilibre: createCompareEquilibre(
          eq.refMaterielRoulant,
          CompareTrainCategory.EQS_MODIFIED,
          compareEquilibreBySubCategory,
          this.getEqModifiedFieldValue(eq.refMaterielRoulant, compareEquilibreBySubCategory),
          this.getEqModifiedFieldValue(eq.otherMaterielRoulant, compareEquilibreBySubCategory),
          eq.otherMaterielRoulant,
        ),
        state: CompareEqState.NONE,
      } as CompareElement;
    });

    return compareElements;
  }

  private getEqModifiedFieldValue(eq: Equilibre, modifiedField: CompareTrainSubCategory): string {
    let modifiedFieldValue = '';
    switch (modifiedField) {
      case CompareTrainSubCategory.MODIFICATION_NB_ELEMENTS:
        modifiedFieldValue = eq.materielCount.toString();
        break;
      case CompareTrainSubCategory.MODIFICATION_TYPE_MATERIEL:
        modifiedFieldValue = this.listTypesMateriel?.find(type => type.type === eq.typeMateriel)?.nom || '';
        break;
      case CompareTrainSubCategory.MODIFICATION_OCCUPATION:
        modifiedFieldValue = eq.miQuai;
        break;
      default:
        console.error(`[GovData] getEqModifiedFieldValue : The modified field ${modifiedField} is not managed.`);
    }

    return modifiedFieldValue;
  }

  /**
   *
   * @param train The train
   * @param modifiedField The modified field
   * @returns the modified field value
   */
  private getModifiedFieldValue(train: Train, modifiedField: CompareTrainSubCategory): string {
    let modifiedFieldValue = '';
    switch (modifiedField) {
      case CompareTrainSubCategory.MODIFICATION_HORAIRE:
        modifiedFieldValue = train.dateHeure;
        break;
      case CompareTrainSubCategory.MODIFICATION_VAQ:
        modifiedFieldValue = train.sens === Sens.ARRIVEE ? train.voieFin : train.voieDebut;
        break;
      case CompareTrainSubCategory.MODIFICATION_TC:
        modifiedFieldValue = train.typeCirculation;
        break;
      case CompareTrainSubCategory.MODIFICATION_VEL:
        modifiedFieldValue = train.sens === Sens.ARRIVEE ? train.voieDebut : train.voieFin;
        break;
      case CompareTrainSubCategory.MODIFICATION_OD:
        modifiedFieldValue = train.sens === Sens.ARRIVEE ? train.origine : train.destination;
        break;
      case CompareTrainSubCategory.MODIFICATION_ITINERAIRE:
        if (train.itineraireRid) {
          // ItineraireRid could be undefined
          modifiedFieldValue = this.listItineraires.find(iti => iti.rid === train.itineraireRid).compositionDisplayed;
        }
        break;
      default:
        console.error(`[GovData] getModifiedFieldValue : The modified field ${modifiedField} is not managed.`);
    }

    return modifiedFieldValue;
  }

  /**
   * Update the number of compare trains by category
   */
  updateNumCompareElementsByCategory() {
    this._numCompareTrainsByCategory = {
      [CompareTrainCategory.TRAINS_ADDED]: 0,
      [CompareTrainCategory.TRAINS_DELETED]: 0,
      [CompareTrainCategory.TRAINS_MODIFIED]: 0,
      [CompareTrainCategory.EQS_MODIFIED]: 0,
      [CompareTrainCategory.ASSEMBLAGES_MODIFIED]: 0,
    };

    this.compareTrainByCategoryMap.forEach(compareTrainByCategory => {
      compareTrainByCategory.compareTrainsBySubCategory.forEach(compareTrainBySubCategory => {
        this._numCompareTrainsByCategory[compareTrainByCategory.category] += compareTrainBySubCategory.compareElements.filter(
          t => t.state === CompareTrainState.NONE,
        ).length;
      });
    });
  }

  /**
   *
   * @param compareTrainCategory The compare train category
   * @returns The compare train category associated to the compare train category
   */
  getCompareTrainByCategoryMap(compareTrainCategory: CompareTrainCategory) {
    return this.compareTrainByCategoryMap.get(compareTrainCategory);
  }

  /**
   * Add the compare element on the select elements that we accept
   * @param compareElement The compare element
   */
  selectCompareElement(compareElement: CompareElement) {
    const compareType = compareElement.compareType;
    if (compareType === CompareTrainCategory.TRAINS_ADDED) {
      if (compareElement.compareTrain) {
        compareElement.compareTrain.trains.forEach(train => this.compareTrainsAddedSelected.set(train.id, { id: train.id }));
      } else if (compareElement.compareEquilibre.groupEquilibre.length > 0) {
        const group = compareElement.compareEquilibre.groupEquilibre;
        const groupTraget = [];
        group.forEach(eq => {
          const equilibreTarget = createPartialEquilibre(eq.arrive, eq.depart);
          groupTraget.push(equilibreTarget);
        });
        const eqId = group[0].id;
        this.compareGroupEquilibresAddedSelected.set(eqId, groupTraget);
      }
    } else if (compareType === CompareTrainCategory.TRAINS_DELETED) {
      if (compareElement.compareTrain) {
        compareElement.compareTrain.trains.forEach(train => this.compareTrainsDeletedSelected.set(train.id, { id: train.id }));
      } else if (compareElement.compareEquilibre.groupEquilibre.length > 0) {
        const group = compareElement.compareEquilibre.groupEquilibre;
        const groupTraget = [];
        group.forEach(eq => {
          const equilibreTarget = createPartialEquilibre(eq.arrive, eq.depart);
          groupTraget.push(equilibreTarget);
        });
        const eqId = group[0].id;
        this.compareGroupEquilibresDeletedSelected.set(eqId, groupTraget);
      }
    } else if (compareType === CompareTrainCategory.TRAINS_MODIFIED) {
      // TRAINS_MODIFIED
      const modifiedField = this.getCompareTrainModifiedField(compareElement.compareTrain);

      const train = compareElement.compareTrain.trains[0];
      if (this.isGovSynchronizedHouat) {
        if (this.compareTrainsChangedSelected.has(train.id)) {
          this.compareTrainsChangedSelected.get(train.id).modifiedFields.push(modifiedField);
        } else {
          const t: Partial<TrainComparisonDTO> = {
            trainId: train.id,
            modifiedFields: [modifiedField],
          };
          this.compareTrainsChangedSelected.set(train.id, t);
        }
      } else {
        // Synchro Passe-minuit
        if (
          this.compareTrainsChangedSelectedPasseMinuit.has(train.id) &&
          this.compareTrainsChangedSelectedPasseMinuit.get(train.id).modifiedFields.indexOf(modifiedField) === -1
        ) {
          this.compareTrainsChangedSelectedPasseMinuit.get(train.id).modifiedFields.push(modifiedField);
        } else {
          const modifiedTrain: ModifiedTrain = {
            modifiedFields: [modifiedField],
            refTrain: {
              sens: train.sens,
              numero: train.numero,
              cich: train.cich,
              dateHeure: train.dateHeure,
            },
          };
          this.compareTrainsChangedSelectedPasseMinuit.set(train.id, modifiedTrain);
        }
      }
    } else if (compareType === CompareTrainCategory.EQS_MODIFIED && this.isGovSynchronizedPasseMinuit) {
      // Equilibres modifiés
      const modifiedField = this.getCompareEquilibreModifiedField(compareElement.compareEquilibre);
      const equilibre = compareElement.compareEquilibre.equilibre;
      const otherEquilibre = compareElement.compareEquilibre.otherEquilibre;
      if (
        this.compareEquilibresChangedSelectedPasseMinuit.has(equilibre.id) &&
        this.compareEquilibresChangedSelectedPasseMinuit.get(equilibre.id).modifiedFields.indexOf(modifiedField) === -1
      ) {
        this.compareEquilibresChangedSelectedPasseMinuit.get(equilibre.id).modifiedFields.push(modifiedField);
      } else {
        const equilibreComparisonDTO: EquilibreComparisonDTO = {
          refMaterielRoulant: createPartialEquilibre(equilibre.arrive, equilibre.depart),
          otherMaterielRoulant: createPartialEquilibre(otherEquilibre.arrive, otherEquilibre.depart),
          modifiedFields: [modifiedField],
        };
        this.compareEquilibresChangedSelectedPasseMinuit.set(equilibre.id, equilibreComparisonDTO);
      }
    } else if (compareType === CompareTrainCategory.ASSEMBLAGES_MODIFIED && this.isGovSynchronizedPasseMinuit) {
      // Assemblages modifiés
      if (compareElement.compareAssemblage) {
        const compareAssemblage = compareElement.compareAssemblage;
        const refGroups: PartialEquilibre[] = [];
        const otherGroups: PartialEquilibre[] = [];
        compareAssemblage.refGroup.forEach(eq => refGroups.push(createPartialEquilibre(eq.arrive, eq.depart)));
        compareAssemblage.otherGroup.forEach(eq => otherGroups.push(createPartialEquilibre(eq.arrive, eq.depart)));
        const eq = compareAssemblage.refGroup[0]?.id ? compareAssemblage.refGroup[0].id : compareAssemblage.otherGroup[0].id;
        const assemblageDto: AssemblageDTO = {
          refGroup: refGroups,
          otherGroup: otherGroups,
          addedTrains: compareAssemblage.addedTrains,
          deletedTrains: compareAssemblage.deletedTrains,
        };
        this.compareAssemblagesSelectedPasseMinuit.set(eq, assemblageDto);
      }
    }
  }

  /**
   * Remove the compare element on the select elements that we reject
   * @param compareElement The compare element
   */
  unselectCompareElement(compareElement: CompareElement) {
    const compareType = compareElement.compareType;
    if (compareType === CompareTrainCategory.TRAINS_ADDED) {
      if (compareElement.compareTrain) {
        compareElement.compareTrain.trains.forEach(train => this.compareTrainsAddedSelected.delete(train.id));
      } else if (compareElement.compareEquilibre.groupEquilibre.length > 0) {
        this.compareGroupEquilibresAddedSelected.delete(compareElement.compareEquilibre.groupEquilibre[0].id);
      }
    } else if (compareType === CompareTrainCategory.TRAINS_DELETED) {
      if (compareElement.compareTrain) {
        compareElement.compareTrain.trains.forEach(train => this.compareTrainsDeletedSelected.delete(train.id));
      } else {
        this.compareGroupEquilibresDeletedSelected.delete(compareElement.compareEquilibre.equilibre.id);
      }
    } else if (compareType === CompareTrainCategory.TRAINS_MODIFIED) {
      // TRAINS_MODIFIED
      const modifiedField = this.getCompareTrainModifiedField(compareElement.compareTrain);
      const train = compareElement.compareTrain.trains[0];

      if (this.isGovSynchronizedHouat) {
        this.unselectCompareElementTrainsModified(train.id, modifiedField, this.compareTrainsChangedSelected);
      } else {
        // Synchro Passe-minuit
        this.unselectCompareElementTrainsModified(train.id, modifiedField, this.compareTrainsChangedSelectedPasseMinuit);
      }
    } else if (compareType === CompareTrainCategory.EQS_MODIFIED && this.isGovSynchronizedPasseMinuit) {
      // Equilibres modifiés
      const modifiedField = this.getCompareEquilibreModifiedField(compareElement.compareEquilibre);
      const equilibre = compareElement.compareEquilibre.equilibre;
      this.unselectCompareElementEquilibresModified(equilibre.id, modifiedField, this.compareEquilibresChangedSelectedPasseMinuit);
    } else if (compareType === CompareTrainCategory.ASSEMBLAGES_MODIFIED && this.isGovSynchronizedPasseMinuit) {
      // Assemblages modifiés

      if (compareElement.compareAssemblage) {
        const assemblage = compareElement.compareAssemblage;
        const eq = assemblage.refGroup[0]?.id ? assemblage.refGroup[0].id : assemblage.otherGroup[0].id;
        this.compareAssemblagesSelectedPasseMinuit.delete(eq);
      }
    }
  }

  /**
   * Update map of compare trains by removing element for TRAINS_MODIFIED
   * @param trainId The train id
   * @param modifiedField The modified field
   * @param mapCompareTrainsModified The map that we want to update
   */
  private unselectCompareElementTrainsModified(
    trainId: number,
    modifiedField: string,
    mapCompareTrainsModified: Map<number, Partial<TrainComparisonDTO> | ModifiedTrain>,
  ) {
    if (mapCompareTrainsModified.has(trainId) && mapCompareTrainsModified.get(trainId).modifiedFields.length > 0) {
      mapCompareTrainsModified.get(trainId).modifiedFields.forEach((mf, i) => {
        if (mf === modifiedField) {
          mapCompareTrainsModified.get(trainId).modifiedFields.splice(i, 1);
        }
        if (mapCompareTrainsModified.get(trainId).modifiedFields.length == 0) {
          mapCompareTrainsModified.delete(trainId);
        }
      });
    } else {
      mapCompareTrainsModified.delete(trainId);
    }
  }

  private unselectCompareElementEquilibresModified(
    equilibreId: number,
    modifiedField: string,
    mapCompareEquilibresModified: Map<number, EquilibreComparisonDTO>,
  ) {
    if (mapCompareEquilibresModified.has(equilibreId) && mapCompareEquilibresModified.get(equilibreId).modifiedFields.length > 0) {
      mapCompareEquilibresModified.get(equilibreId).modifiedFields.forEach((mf, i) => {
        if (mf === modifiedField) {
          mapCompareEquilibresModified.get(equilibreId).modifiedFields.splice(i, 1);
        }
        if (mapCompareEquilibresModified.get(equilibreId).modifiedFields.length == 0) {
          mapCompareEquilibresModified.delete(equilibreId);
        }
      });
    } else {
      mapCompareEquilibresModified.delete(equilibreId);
    }
  }

  /**
   *
   * @param compareTrain The compare train
   * @returns The modified filed corresponding to the compare train sub category
   */
  getCompareTrainModifiedField(compareTrain: CompareTrain): string {
    let modifiedField: string;
    switch (compareTrain.modifiedField) {
      case CompareTrainSubCategory.MODIFICATION_HORAIRE:
        modifiedField = ModifiedField.HORAIRE;
        break;
      case CompareTrainSubCategory.MODIFICATION_VAQ:
        modifiedField = ModifiedField.VOIE_A_QUAI;
        break;
      case CompareTrainSubCategory.MODIFICATION_TC:
        modifiedField = ModifiedField.TYPE_CIRCULATION;
        break;
      case CompareTrainSubCategory.MODIFICATION_VEL:
        modifiedField = ModifiedField.VOIE_EN_LIGNE;
        break;
      case CompareTrainSubCategory.MODIFICATION_ITINERAIRE:
        modifiedField = ModifiedField.ITINERAIRE;
        break;
      case CompareTrainSubCategory.MODIFICATION_OD:
        modifiedField = compareTrain.trains[0].sens === Sens.ARRIVEE ? ModifiedField.ORIGINE : ModifiedField.DESTINATION;
        break;
      default:
        console.error(
          `[GovData] getCompareTrainModifiedField : The modified field ${compareTrain.modifiedField} does not match any of CompareTrainSubCategory.`,
        );
    }

    return modifiedField;
  }

  /**
   *
   * @param compareEquilibre The compare equilibre
   * @returns The modified filed corresponding to the compare equilibre sub category
   */
  getCompareEquilibreModifiedField(compareEquilibre: CompareEquilibre): string {
    let modifiedField: string;
    switch (compareEquilibre.modifiedField) {
      case CompareTrainSubCategory.MODIFICATION_NB_ELEMENTS:
        modifiedField = ModifiedField.NB_ELEMENTS;
        break;
      case CompareTrainSubCategory.MODIFICATION_TYPE_MATERIEL:
        modifiedField = ModifiedField.TYPE_MATERIEL;
        break;
      case CompareTrainSubCategory.MODIFICATION_OCCUPATION:
        modifiedField = ModifiedField.MI_QUAI;
        break;
      default:
        console.error(
          `[GovData] getCompareEquilibreModifiedField : The modified field ${compareEquilibre.modifiedField} does not match any of CompareTrainSubCategory.`,
        );
    }

    return modifiedField;
  }
  /**
   * Compute tp comparison to validate
   * @returns The tp comparison validated
   */
  getTpComparisonValidated(): TransportationPlanComparison {
    const tpComparisonValidated: TransportationPlanComparison = {
      addedTrains: Array.from(this.compareTrainsAddedSelected.values()) as Train[],
      deletedTrains: Array.from(this.compareTrainsDeletedSelected.values()) as Train[],
      modifiedTrains: Array.from(this.compareTrainsChangedSelected.values()) as TrainComparisonDTO[],
    };

    return tpComparisonValidated;
  }

  /**
   * Compute passe minuit comparison to validate
   * @returns The passe minuit comparison validated
   */
  getPasseMinuitComparisonValidated(): PasseMinuitComparisonDTO {
    const passeMinuitComparisonValidated: PasseMinuitComparisonDTO = {
      addedGroups: Array.from(this.compareGroupEquilibresAddedSelected.values()) as EquilibreDto[][],
      deletedGroups: Array.from(this.compareGroupEquilibresDeletedSelected.values()) as EquilibreDto[][],
      modifiedTrains: Array.from(this.compareTrainsChangedSelectedPasseMinuit.values()) as TrainComparisonDTO[],
      modifiedMrs: Array.from(this.compareEquilibresChangedSelectedPasseMinuit.values()) as EquilibreComparisonDTO[],
      modifiedGroups: Array.from(this.compareAssemblagesSelectedPasseMinuit.values()) as AssemblageDTO[],
    };

    return passeMinuitComparisonValidated;
  }

  /**
   * Reset compare trains selected maps
   */
  resetCompareMaps() {
    this.compareTrainByCategoryMap.clear();
    this.compareTrainsAddedSelected.clear();
    this.compareTrainsDeletedSelected.clear();
    this.compareTrainsChangedSelected.clear();
    this.compareGroupEquilibresAddedSelected.clear();
    this.compareGroupEquilibresDeletedSelected.clear();
    this.compareTrainsChangedSelectedPasseMinuit.clear();
    this.compareEquilibresChangedSelectedPasseMinuit.clear();
    this.compareAssemblagesSelectedPasseMinuit.clear();
  }

  //
  // ----- F2k -----
  //

  /**
   * @returns The F2k map
   */
  getF2kMap(): Map<string, Train[]> {
    return this.f2kMap;
  }

  /**
   * Process the F2k map with trains of the eq
   * @param eqDto The eqDto
   */
  private processF2kTrainsFromEq(eqDto: EquilibreDto) {
    this.processF2kTrain(eqDto.arrive);
    this.processF2kTrain(eqDto.depart);
  }

  /**
   * Add the train to the F2k map if it has a F2k metadata
   * @param train The train
   */
  private processF2kTrain(train: Train) {
    if (train && train.metadonnees) {
      if (train.metadonnees.F2K_TRAIN_NOT_FOUND) {
        if (!this.f2kMap.has(F2K_METADATA_KEY.F2K_TRAIN_NOT_FOUND)) {
          this.f2kMap.set(F2K_METADATA_KEY.F2K_TRAIN_NOT_FOUND, []);
        }
        this.f2kMap.get(F2K_METADATA_KEY.F2K_TRAIN_NOT_FOUND).push(train);
      } else if (train.metadonnees.F2K_ITI_NOT_FOUND) {
        if (!this.f2kMap.has(F2K_METADATA_KEY.F2K_ITI_NOT_FOUND)) {
          this.f2kMap.set(F2K_METADATA_KEY.F2K_ITI_NOT_FOUND, []);
        }
        this.f2kMap.get(F2K_METADATA_KEY.F2K_ITI_NOT_FOUND).push(train);
      }
    }
  }

  //
  // ----- 2TMV -----
  //

  private is2TMV(govVaqList: VaqInfo[]): boolean {
    return govVaqList?.find(vaq => hasVaqMiQuais(vaq)) ? true : false;
  }

  //
  // ----- GOV Display preferences -----
  //

  /**
   * Update eq font size on GOV display preferences
   * @param fontSize the font size
   */
  updateUserPreferences(eqFontSize: number) {
    this.userPreferences.eqFontSize = eqFontSize;
  }

  updateUserPreferencesShowItiSecondaires(showItiSecondaires: boolean) {
    this.userPreferences.showItiSecondaires = showItiSecondaires;
  }

  //
  // ----- Filter VAQ -----
  //

  updateFilteredVaqLists(govVaqList: VaqInfo[], filteredGovVaqList: VaqInfo[]) {
    this.govVaqList = govVaqList;
    this.filteredGovVaqList = filteredGovVaqList;
  }
}

//
// Local helper functions
//

/**
 * Convert the rid of a "conception" GOV to the "1960-01-01" date.
 *
 * That's because "conception" GOVs have a value like "2019A1"
 * as their rid, but their equilibres have "1960-01-01" in their dateHeure.
 */
export function convertConceptionRidToFakeDate(pdtRid: string) {
  const DATE_YMD_REGEX = /\d{4}-\d{2}-\d{2}/;
  if (!DATE_YMD_REGEX.test(pdtRid)) {
    // the rid doesn't have the YYYY-MM-DD format
    return CONCEPTION_TP_DATE;
  }
  return pdtRid;
}

/**
 * Get the last JT applied info
 * @param lastAppliedJt The last JT applied info
 * @returns separated description and id of the last JT applied
 */
function getlastAppliedJtInfo(lastAppliedJt: string): { lastAppliedJtDescription: string; lastAppliedJtId: number } {
  if (lastAppliedJt) {
    const lastAppliedJtSplit = lastAppliedJt.split('|');
    return { lastAppliedJtDescription: lastAppliedJtSplit[0], lastAppliedJtId: +lastAppliedJtSplit[1] };
  }
  return { lastAppliedJtDescription: undefined, lastAppliedJtId: undefined };
}
