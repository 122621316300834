import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

import { MenuItem } from 'primeng/api';

import { BaseComponent } from '@app/base.component';
import { EXPORT_TYPES, WorkMode } from '@app/models';
import { USER_PERMISSION } from '@app/auth';

@Component({
  selector: 'app-export-menu',
  templateUrl: './export-menu.component.html',
  styleUrls: ['./export-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportMenuComponent extends BaseComponent implements OnInit {
  @Input() workMode: WorkMode;
  @Input() userPermissions: USER_PERMISSION[] = [];

  @Output() export = new EventEmitter<{ what: EXPORT_TYPES; data?: any }>();

  menuItems: MenuItem[];
  correspFormModel: { min: number; max: number } = { min: 10, max: 20 };
  isDialogExportCorrespondancesVisible = false;

  constructor() {
    super();
  }

  ngOnInit() {
    this.menuItems = [
      // All items must be added & ordered alphabetically
      {
        label: 'Correspondances',
        command: () => this.showDialogExportCorrespondances(),
        visible: this.userPermissions.includes(USER_PERMISSION.CORRESPONDANCES),
      },
      {
        label: 'Écarts roulements matériel',
        command: () => this.exportRoulements(),
        visible: this.workMode === WorkMode.Adaptation && this.userPermissions.includes(USER_PERMISSION.ROULEMENTS),
      },
      {
        label: 'Fichier Base Gare',
        command: () => this.exportBaseGare(),
        visible: this.userPermissions.includes(USER_PERMISSION.EXPORT_BASE_GARE),
      },
      {
        label: 'Fichier Groix',
        command: () => this.exportGroix(),
        visible: this.userPermissions.includes(USER_PERMISSION.EXPORT_GROIX),
      },
      { label: 'Fichier TST', command: () => this.exportTST(), visible: this.workMode !== WorkMode.Conception },
      { label: 'GOV PDF', command: () => this.exportPDFViaDialog(), visible: true },
      // { label: 'Fichier PDF (page)', command: (event) => this.exportPDFViaPage(),
      //   visible: true },
      { label: 'Liste des conflits', command: () => this.exportConflicts() },
      {
        label: 'Plan de transport',
        command: () => this.exportOpengov(),
        visible: this.userPermissions.includes(USER_PERMISSION.IMPORT_EXPORT_OPENGOV),
      },
    ];
  }

  closeDialogExportCorrespondances(clickedOk: boolean) {
    if (clickedOk) {
      this.exportCorrespondances(this.correspFormModel.min, this.correspFormModel.max);
    }
    this.isDialogExportCorrespondancesVisible = false;
  }

  //

  private exportGroix() {
    this.export.emit({ what: EXPORT_TYPES.GROIX });
  }

  private exportPDFViaDialog() {
    this.export.emit({ what: EXPORT_TYPES.PDF_DIALOG });
  }

  private exportPDFViaPage() {
    this.export.emit({ what: EXPORT_TYPES.PDF_PAGE });
  }

  private exportOpengov() {
    this.export.emit({ what: EXPORT_TYPES.OPENGOV });
  }

  private exportTST() {
    this.export.emit({ what: EXPORT_TYPES.TST });
  }

  private exportConflicts() {
    this.export.emit({ what: EXPORT_TYPES.CONFLICTS });
  }

  private exportRoulements() {
    this.dialog
      .show({
        title: 'Attention',
        content: `Le flux de récupération des données de roulement n'est pas encore stable. 
      En cas d'erreur, attendez 30 minutes avant de réessayer. En cas de nouvel échec, veuillez contacter le support OpenGOV.`,
      confirmButtonLabel: 'Continuer',
    })
      .subscribe(clickedOk => {
        if (clickedOk) {
          this.export.emit({ what: EXPORT_TYPES.ROULEMENTS });
        }
      });
  }

  private exportCorrespondances(min: number, max: number) {
    this.export.emit({ what: EXPORT_TYPES.CORRESPONDANCES, data: { min, max } });
  }

  private showDialogExportCorrespondances() {
    this.correspFormModel = { min: 10, max: 20 };
    this.isDialogExportCorrespondancesVisible = true;
  }

  private exportBaseGare() {
    this.export.emit({ what: EXPORT_TYPES.BASE_GARE });
  }
}
